import type { MenuLinks } from '~/types/CPMenu'

export const useCPLinks = () => {
  const { t } = useI18n()
  const { isWhitelabelBrand } = useRuntimeConfig().public
  const { $auth } = useNuxtApp()

  const links: MenuLinks[] = [
    {
      link: 'profile-bookings',
      heading: t('cp.menu-item.my-bookings.heading'),
      text: t('cp.menu-item.my-bookings.text'),
      icon: 'material-symbols-light:receipt-long-outline',
    },
    ...($auth.user.value.editCustomerEnabled
      ? [
          {
            link: 'profile-details',
            heading: t('cp.menu-item.personal-details.heading'),
            text: t('cp.menu-item.personal-details.text'),
            icon: 'material-symbols-light:account-circle',
          },
        ]
      : []),
    ...(!isWhitelabelBrand
      ? [
          {
            link: 'profile-favorites',
            heading: t('cp.menu-item.favorites.heading'),
            text: t('cp.menu-item.favorites.text'),
            icon: 'material-symbols-light:bookmark-heart-outline-sharp',
          },
        ]
      : []),
  ]

  return links
}
